<template>
    <div>
    <h2 class="col-9">{{ $t("compta.informations") }}</h2>
        <div class="box">

            <AccountingAjout
                :accountingplan_id="accountingplan_id"
                :readonly.sync="isReadOnly"
                :readonly_tiers="true"
                @ok="isReadOnly = true"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
    import TableAction from "@/mixins/TableAction.js"
    import Accounting from "@/mixins/Accounting.js"

    export default {
        name: "AccountingInformations",
        mixins: [TableAction, Accounting],
        props: ['accountingplan_id'],
        components: {
			AccountingAjout: () => import('@/components/Accounting/AccountingAjout'),
        },
        data: () => ({
            isReadOnly: true
        }),
        created() {
            this.isReadOnly = this.$route.query.mode !== 'edit'
        }
    }
</script>
